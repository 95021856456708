import { ButtonOld } from "~/components/ButtonOld";
import { ChevronRightIcon } from "~/components/svg/icons";
import { LogoWhiteIcon } from "~/components/svg/logo";
import { Typo } from "~/components/Typo";
import { Wrapper } from "~/components/ui/wrapper";
import {
  categoriesWithBrandsListingPage,
  disabledCategories,
  footerSecondaryNavItems,
} from "~/config/navigation";
import { cn } from "~/utils/classnames";

import "./Footer.css";
import type { FooterProps } from "./Footer.types";

/**
 * Composant de pied de page.
 */
export const Footer = ({ navbarCategories = [], className, ...props }: FooterProps) => {
  return (
    <footer className={cn("Footer", className)} {...props}>
      <div className="Footer-bg">
        <Wrapper className="Footer-wrapper">
          <LogoWhiteIcon className="Footer-logo" />
          <div className="Footer-main">
            <div className="Footer-main-leftCol">
              <Typo
                as="p"
                fontColor="white"
                fontWeight="medium"
                fontSize="xs"
                className="Footer-main-leftCol-text"
              >
                POwR CONNECT est la plateforme e-commerce n°1 du solaire photovoltaïque pour les
                professionnels du PV, installateurs et développeurs PV.
                <br />
                POwR CONNECT dispose du stock le plus important en France et l&apos;un des plus
                conséquents en Europe, offrant ainsi une large gamme de modules photovoltaïques,
                onduleurs solaires, systèmes de fixation, batteries solaires et autres accessoires,
                indispensables à toute installation PV. Son succès s&apos;appuie également sur une
                puissante organisation logistique ainsi que sur son bureau d&apos;études PV intégré.
                <br />
                Véritable référence du professionnel PV, POwR Connect a pour mission de faciliter
                les projets PV de ses clients professionnels et leur offrir un maximum de solutions
                photovoltaïques aux meilleurs prix.
              </Typo>
            </div>

            <div className="Footer-main-rightCol">
              <span className="Footer-main-rightCol-title">NOS PRODUITS</span>
              <nav
                aria-label="Navigation de bas de page pour les catégories de produits"
                className="Footer-main-rightCol-nav"
              >
                {navbarCategories
                  ? navbarCategories
                      .filter((item) => !disabledCategories.includes(item.slug))
                      .map((category) => (
                        <ButtonOld
                          as="a"
                          variant="ghost"
                          IconComponent={<ChevronRightIcon />}
                          iconPosition="right"
                          hoverEffect="iconRotate"
                          color="white"
                          key={category.id}
                          label={category.title || ""}
                          href={
                            category.slug && categoriesWithBrandsListingPage.includes(category.slug)
                              ? `/${category.slug}/marques`
                              : `/${category.slug}`
                          }
                          className="Footer-main-rightCol-navLink"
                        />
                      ))
                  : null}
              </nav>
            </div>
          </div>
        </Wrapper>
      </div>
      <div className="Footer-bottom-bg">
        <Wrapper className="Footer-bottom">
          <nav aria-label="Navigation de bas de page secondaire" className="Footer-bottom-nav">
            {footerSecondaryNavItems.map((link) => (
              <Typo
                className="Footer-bottom-navLink"
                as="a"
                fontSize="xs"
                href={link.slug}
                key={link.slug}
              >
                {link.label}
              </Typo>
            ))}
          </nav>
        </Wrapper>
      </div>
    </footer>
  );
};
