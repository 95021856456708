import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { NavLink, useLocation } from "@remix-run/react";
import type { Dispatch, SetStateAction } from "react";
import { Fragment, useMemo, useState } from "react";
import { HiOutlineChevronDown, HiOutlineChevronRight, HiOutlineXMark } from "react-icons/hi2";
import type { Category } from "~types/api/category.types";

import newBadgeImg from "~/assets/images/badge-new.png";
import { Link } from "~/components/Link";
import {
  categoriesNavGroups,
  categoriesWithBrandsListingPage,
  headerMobileSecondaryNavItems,
} from "~/config/navigation";
import { cn } from "~/utils/classnames";

type Item = {
  label: string;
  slug: string | null | undefined;
  children: Item[] | null | undefined;
  icon: any;
  order: number;
};

const navbarItemStyles =
  "flex grow cursor-pointer items-center justify-center bg-black px-5 py-3.5 text-center text-sm font-medium text-white hover:bg-primary gap-2 tracking-wide";

export const NavItem = ({ item }: { item: Item }) => {
  const location = useLocation();

  const isLinkActive = (link: string) => {
    return location.pathname.includes(link);
  };

  const isGroupActive = (items: any[]) => {
    return items.some((item) => isLinkActive(item.slug));
  };

  const [isGroupOpen, setIsGroupOpen] = useState(false);

  return (
    <>
      {item.children && item.children.length > 0 ? (
        <div
          className="relative grow"
          onMouseEnter={() => setIsGroupOpen(true)}
          onMouseLeave={() => setIsGroupOpen(false)}
          onFocus={() => setIsGroupOpen(true)}
          onBlur={() => setIsGroupOpen(false)}
        >
          <button
            className={cn(
              navbarItemStyles,
              "w-full",
              isGroupActive(item.children) && !isGroupOpen && "bg-primary hover:bg-primary-600"
            )}
            data-testid="electrical-equipment-button"
          >
            {item.label}
            <HiOutlineChevronDown className="h-4 w-4 stroke-2" />
          </button>
          <div
            className={cn(
              "absolute left-1/2 top-full hidden w-full -translate-x-1/2",
              isGroupOpen && "block"
            )}
          >
            {item.children.map((item) => (
              <Link
                className={cn(
                  navbarItemStyles,
                  "w-full",
                  item.slug && isLinkActive(item.slug) && "bg-primary hover:bg-primary-600"
                )}
                to={
                  item.slug && categoriesWithBrandsListingPage.includes(item.slug)
                    ? `/${item.slug}/marques`
                    : `/${item.slug}`
                }
                key={item.slug}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <NavLink
          to={
            item.slug && categoriesWithBrandsListingPage.includes(item.slug)
              ? `/${item.slug}/marques`
              : `/${item.slug}`
          }
          className={({ isActive }) =>
            cn(navbarItemStyles, isActive && "bg-primary hover:bg-primary-600")
          }
          key={item.slug}
        >
          {item.label}
        </NavLink>
      )}
    </>
  );
};

export const Navbar = ({
  categories = [],
  isMobileOpen,
  setIsMobileOpen,
  className,
  ...props
}: {
  categories?: Category[] | null | undefined;
  isMobileOpen: boolean;
  setIsMobileOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
}) => {
  const location = useLocation();

  const isLinkActive = (link: string) => {
    return location.pathname.includes(link);
  };

  const navItems = useMemo(() => {
    const itemsWithoutChildren =
      categories
        ?.filter((category) =>
          categoriesNavGroups.every((group) => !group.childrenSlugs.includes(category.slug))
        )
        .map((category) => ({
          label: category.title,
          slug: category.slug,
          children: null,
          icon: null,
          order: category.sort,
        })) ?? [];

    const itemsWithChildrens = categoriesNavGroups
      .map((group) => {
        const children = categories
          ?.filter((category) => group.childrenSlugs.includes(category.slug))
          .map((category) => ({
            label: category.title,
            slug: category.slug,
            icon: null,
            order: category.sort,
            children: null,
          }));
        return {
          label: group.label,
          slug: null,
          icon: null,
          order: children?.[0]?.order ?? 999,
          children: children?.sort((a, b) => a.order - b.order) ?? [],
        };
      })
      .sort((a, b) => a.order - b.order);

    return [...itemsWithoutChildren, ...itemsWithChildrens].sort((a, b) => a.order - b.order);
  }, [categories]);

  return (
    <>
      {/* Desktop */}
      <nav
        aria-label="Barre de navigation principale des catégories de produits"
        className={cn("hidden bg-black lg:block", className)}
        data-testid="main-menu"
        {...props}
      >
        <div className="flex justify-center">
          {navItems
            .sort((a, b) => a.order - b.order)
            .map((item) =>
              item ? <NavItem item={item} key={`${item.label}-${item.slug}`} /> : null
            )}

          <Link
            className={cn(
              "relative",
              navbarItemStyles,
              isLinkActive("outils-et-services") && "bg-primary hover:bg-primary-600"
            )}
            to="/outils-et-services"
          >
            <img src={newBadgeImg} alt="Nouveau" className="absolute right-0 top-0 h-10 w-10" />
            Outils et services
          </Link>
        </div>
      </nav>

      {/* Mobile */}
      <Transition.Root as={Fragment} show={isMobileOpen}>
        <Dialog
          as="div"
          className="relative z-40 text-grey-800 lg:hidden"
          onClose={setIsMobileOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 z-40">
            <Transition.Child
              as={Fragment}
              enter="transition duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel
                as="nav"
                className="absolute bottom-0 right-0 top-0 flex min-w-[280px] flex-col overflow-y-auto border-l border-grey-200 bg-white px-6 pb-12 shadow-xl"
                data-testid="slideout-menu"
              >
                <div className="flex pb-4 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-grey-500"
                    onClick={() => setIsMobileOpen(false)}
                  >
                    <span className="sr-only">Fermer le menu</span>
                    <HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mb-4 mt-2 text-lg font-semibold">Nos produits :</div>
                <ul className="space-y-1">
                  {navItems.map((item) => (
                    <li key={item.label}>
                      {item.children && item.children.length > 0 ? (
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={cn(
                                  "flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-medium leading-6 hover:bg-grey-50"
                                )}
                              >
                                {/* {item?.icon ? (
                                  <item.icon
                                    className="h-6 w-6 shrink-0 text-grey-400"
                                    aria-hidden="true"
                                  />
                                ) : null} */}
                                {item.label}
                                <HiOutlineChevronRight
                                  className={cn(
                                    open && "rotate-90",
                                    "ml-auto h-5 w-5 shrink-0 text-grey-500 transition-transform"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-75 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-75 opacity-0"
                              >
                                <Disclosure.Panel
                                  as="ul"
                                  className="mt-1 space-y-1 pl-4 pr-0"
                                  unmount={false}
                                >
                                  {item.children.map((subItem) => (
                                    <li key={subItem.label}>
                                      <NavLink
                                        to={
                                          subItem.slug &&
                                          categoriesWithBrandsListingPage.includes(subItem.slug)
                                            ? `/${subItem.slug}/marques`
                                            : `/${subItem.slug}`
                                        }
                                        onClick={() => setIsMobileOpen(false)}
                                        className={({ isActive }) =>
                                          cn(
                                            isActive ? "bg-grey-50" : "hover:bg-grey-50",
                                            "block rounded-md py-2 pl-4 pr-4 text-sm leading-6"
                                          )
                                        }
                                      >
                                        {subItem.label}
                                      </NavLink>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </Transition>
                            </>
                          )}
                        </Disclosure>
                      ) : (
                        <NavLink
                          to={
                            item.slug && categoriesWithBrandsListingPage.includes(item.slug)
                              ? `/${item.slug}/marques`
                              : `/${item.slug}`
                          }
                          onClick={() => setIsMobileOpen(false)}
                          className={({ isActive }) =>
                            cn(
                              isActive ? "bg-grey-50" : "hover:bg-grey-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6"
                            )
                          }
                        >
                          {/* {item?.icon ? (
                            <item.icon
                              className="h-6 w-6 shrink-0 text-grey-400"
                              aria-hidden="true"
                            />
                          ) : null} */}
                          {item.label}
                        </NavLink>
                      )}
                    </li>
                  ))}
                </ul>
                <ul className="mt-4 space-y-1 border-t border-grey-200 pt-4">
                  <li>
                    <NavLink
                      to="/outils-et-services"
                      onClick={() => setIsMobileOpen(false)}
                      className={({ isActive }) =>
                        cn(
                          isActive ? "bg-grey-50" : "hover:bg-grey-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-grey-800"
                        )
                      }
                    >
                      Outils et services
                    </NavLink>
                  </li>
                </ul>
                <ul className="mt-4 space-y-1 border-t border-grey-200 pt-4">
                  {headerMobileSecondaryNavItems.map((item) => (
                    <li key={item.to}>
                      <NavLink
                        to={item.to}
                        onClick={() => setIsMobileOpen(false)}
                        className={({ isActive }) =>
                          cn(
                            isActive ? "bg-grey-50" : "hover:bg-grey-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-grey-800"
                          )
                        }
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
